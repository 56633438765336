import logo from './logo.svg';
import './App.css';
import { useMoralis, useERC20Balances } from "react-moralis";
import { useEffect } from "react";

function App() {

  const { authenticate, isAuthenticated, user } = useMoralis();

  const { fetchERC20Balances, data, isLoading, isFetching, error } = useERC20Balances();


  useEffect(()=>{
    console.log(user)

    if (isAuthenticated) {
      fetchERC20Balances({params : {chain:'polygon'} });
    }
  },[user, isAuthenticated])

  useEffect(()=>{
    console.log(data)
  },[data])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        {
          isAuthenticated ?
            <div>
              <h1>Hi {user.get("username")}</h1>
              <p>{JSON.stringify(data, null, 2)}</p>
            </div>
          :
            <button onClick={() => authenticate()}>Authenticate</button>
        }
      </header>
    </div>
  );
}

export default App;
